
<template>
  <span class="text-no-wrap">
    <v-icon
      v-for="index in max"
      :key="index"
      x-small
      class="mt-n1"
      style="margin-right:1px"
    >
      {{ price >= index ? 'fas' : 'fal' }} fa-dollar-circle
    </v-icon>
  </span>
</template>
<script>
export default {
  props: {
    max: {
      type: Number,
      default: 4,
    },
    price: {
      type: Number,
      default: 0,
    },
  },
}
</script>